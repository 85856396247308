.booking-container {
  margin-left: 10rem;
  margin-right: 10rem;
}

@media only screen and (max-width: 992px) {
  .booking-container {
    margin: 2rem;
  }
}
