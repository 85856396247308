.elevating {
  font-size: 36px;
  font-weight: 600;
  /* width: 650px; */
  letter-spacing: 2px;
}
.heading_main_img {
  /* height: 60rem; */
  max-width: 60rem;
}

.accordion_content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}
.open {
  max-height: fit-content;
  transition: max-height 0.5s ease-in-out;
}
.list_style {
  list-style: none; /* Hide default markers */
  padding-left: 20px;
}
.list_style li::before {
  content: "\2022"; /* Unicode for bullet character */
  color: red; /* Change the color to your desired color */
  margin-right: 10px; /* Adjust the spacing between marker and content */
}
.students_hired {
  max-width: 19rem;
  height: 20rem;
  max-height: 18rem;
  background-color: #0f42db;
}
.hiring_partners {
  max-width: 19rem;
  height: 20rem;
  max-height: 18rem;
  background-color: #ff2852;
}
.average_salary {
  max-width: 39.5rem;
  height: 20rem;
  max-height: 18rem;
  background-color: #ffffff;
}
.course_enroll_btn {
  background-color: #ff2852;
}
.course_specificition {
  max-width: 5.5rem;
}
.course_duration {
  width: 100%;
  max-width: 7.5rem;
}

@media only screen and (max-width: 992px) {
  .elevating {
    font-size: 28px;
  }
}
@media only screen and (max-width: 1200px) {
  .course_specificition {
    max-width: 5rem;
  }
  .course_duration {
    max-width: 6rem;
  }
}
@media only screen and (max-width: 568px) {
  .elevating {
    font-size: 24px;
  }
}
@media (min-width: 1400px) {
  .heading_main_img {
    height: 70rem;
    max-width: 80rem;
  }
}

.vertical-text {
  writing-mode: vertical-rl;
  position: absolute;
  top: 0;
  left: 0;
  height: 90%;
  padding: 15px;
  color: white;
  background-color: #111111;
  transform: rotate(180deg);
  text-align: start;
}

.vertical-text .p-5 {
  margin-bottom: 20%;
}

.vertical-span {
  font-weight: 100;
  opacity: 0.65 !important;
  letter-spacing: 4px;
}
