body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Jost {
  font-family: "Jost";
}

.DM_Serif_Text {
  font-family: "DM Serif Text", serif;
}
.open_sans {
  font-family: "Open Sans", sans-serif;
}
.letter_space{
  letter-spacing: 2px;
}
.cursor_pointer {
  cursor: pointer;
}
.color_blue{
  color: #0F42DB;
}
.color_white{
  color: white;
}
.bg_color_1D72DB{
  background-color: #1D72DB;
}
.border_radus_7px{
  border-radius: 7px;
}
.color_black{
  color: black;
}
.fw_300 {
  font-weight: 300;
}

.fw_400 {
  font-weight: 400;
}

.fw_500 {
  font-weight: 500;
}

.fw_600 {
  font-weight: 600;
}

.fw_700 {
  font-weight: 700;
}

.fw_900 {
  font-weight: 900;
}
.border_radus_7px{
  border-radius: 7px;
}
.border_radus_12px{
  border-radius: 12px;
}
.fs_12 {
  font-size: 12px;
}

.fs_13 {
  font-size: 13px;
}

.fs_14 {
  font-size: 14px;
}

.fs_15 {
  font-size: 15px;
}

.fs_16 {
  font-size: 16px;
}

.fs_17 {
  font-size: 17px;
}

.fs_18 {
  font-size: 18px;
}

.fs_19 {
  font-size: 19px;
}

.fs_20 {
  font-size: 20px;
}

.fs_21 {
  font-size: 21px;
}

.fs_22 {
  font-size: 22px;
}

.fs_23 {
  font-size: 23px;
}

.fs_24 {
  font-size: 24px;
}

.fs_25 {
  font-size: 25px;
}

.fs_26 {
  font-size: 26px;
}

.fs_27 {
  font-size: 27px;
}

.fs_28 {
  font-size: 28px;
}
.fs_30 {
  font-size: 30px;
}
.fs_32 {
  font-size: 32px;
}
.fs_34 {
  font-size: 34px !important;
}
.fs_35{
  font-size: 35px;
}
.fs_36{
  font-size: 36px;
}
.fs_40{
  font-size: 40px;
}
.fs_44{
  font-size: 44px;
}
.fs_50{
  font-size: 50px;
}
.border_1px_F5F5F5{
  border: 1px solid #F5F5F5;
}
.border_1px_030406{
  border: 1px solid #030406;
}
.border_color_0F42DB{
  border: 1px solid #0F42DB;
}
.border_radus_5px{
  border-radius : 5px ;
}
.border_radus_7px{
  border-radius : 7px ;
}
.border_radus_18px{
  border-radius : 18px ;
}
.color_FF2852{
  color: #FF2852;
}
.color_030406{
  color: #030406;
}
.letter_spacing{
  letter-spacing: 2px;
}
.color_bg_white{
  background-color: white;
}
.color_bg_FF28521A{
  background-color: #FF28521A;
}
.color_0F42DB{
  color: #0F42DB;
}
.color_969696{
  color: #969696;
}
.color_0F42DB{
  color: #0F42DB;
}
.color_E3E3E3{
  color: #E3E3E3;
}
.color_E1E1E1{
  color: #E1E1E1;
}
.boredr_1px_0F42DB{
  border: 1px solid #0F42DB;
}
.color_bg_F5F5F580{
  background-color:#f5f5f580;
}
.color_bg_0F42DB{
  background-color: #0F42DB;
}
.line_height_4rem{
  line-height: 3.7rem;
}
@media only screen and (max-width: 1200px) {
  .fs_lg_10 {
    font-size: 10px;
  }

  .fs_lg_11 {
    font-size: 11px;
  }

  .fs_lg_12 {
    font-size: 12px;
  }

  .fs_lg_13 {
    font-size: 13px;
  }

  .fs_lg_14 {
    font-size: 14px;
  }

  .fs_lg_15 {
    font-size: 15px;
  }

  .fs_lg_16 {
    font-size: 16px;
  }

  .fs_lg_17 {
    font-size: 17px;
  }

  .fs_lg_18 {
    font-size: 18px;
  }

  .fs_lg_18 {
    font-size: 19px;
  }

  .fs_lg_20 {
    font-size: 20px;
  }

  .fs_lg_21 {
    font-size: 21px;
  }

  .fs_lg_22 {
    font-size: 22px;
  }

  .fs_lg_23 {
    font-size: 23px;
  }

  .fs_lg_24 {
    font-size: 24px;
  }

  .fs_lg_25 {
    font-size: 25px;
  }

  .fs_lg_26 {
    font-size: 26px;
  }

  .fs_lg_27 {
    font-size: 27px;
  }

  .fs_lg_28 {
    font-size: 28px;
  }

  .fs_lg_29 {
    font-size: 29px;
  }

  .fs_lg_30 {
    font-size: 30px;
  }

  .fs_lg_31 {
    font-size: 31px;
  }

  .fs_lg_32 {
    font-size: 32px;
  }

  .fs_lg_33 {
    font-size: 33px;
  }

  .fs_lg_34 {
    font-size: 34px;
  }

  .fs_lg_35 {
    font-size: 35px;
  }
  .fs_lg_36 {
    font-size: 36px;
  }
  .fs_lg_38 {
    font-size: 38px;
  }

  .fs_lg_40 {
    font-size: 40px;
  }
  .fs_lg_45 {
    font-size: 45px;
  }
  .fs_lg_50 {
    font-size: 50px;
  }
  .fs_lg_60 {
    font-size: 60px;
  }
  .fs_lg_70 {
    font-size: 75px;
  }
  .fs_lg_80 {
    font-size: 80px;
  }
}
@media only screen and (max-width: 992px) {
  .fs_md_10 {
    font-size: 10px;
  }

  .fs_md_11 {
    font-size: 11px;
  }

  .fs_md_12 {
    font-size: 12px;
  }

  .fs_md_13 {
    font-size: 13px;
  }

  .fs_md_14 {
    font-size: 14px;
  }

  .fs_md_15 {
    font-size: 15px;
  }

  .fs_md_16 {
    font-size: 16px;
  }

  .fs_md_17 {
    font-size: 17px;
  }

  .fs_md_18 {
    font-size: 18px;
  }

  .fs_md_19 {
    font-size: 19px;
  }

  .fs_md_20 {
    font-size: 20px;
  }

  .fs_md_21 {
    font-size: 21px;
  }

  .fs_md_22 {
    font-size: 22px;
  }

  .fs_md_23 {
    font-size: 23px;
  }

  .fs_md_24 {
    font-size: 24px;
  }

  .fs_md_25 {
    font-size: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .fs_sm_10 {
    font-size: 7.3px;
  }

  .fs_sm_11 {
    font-size: 11px;
  }

  .fs_sm_12 {
    font-size: 12px;
  }

  .fs_sm_13 {
    font-size: 13px;
  }

  .fs_sm_14 {
    font-size: 14px;
  }

  .fs_sm_15 {
    font-size: 15px;
  }

  .fs_sm_16 {
    font-size: 16px;
  }

  .fs_sm_17 {
    font-size: 17px;
  }

  .fs_sm_18 {
    font-size: 18px;
  }

  .fs_sm_19 {
    font-size: 19px;
  }

  .fs_sm_20 {
    font-size: 20px;
  }

  .fs_sm_21 {
    font-size: 21px;
  }

  .fs_sm_22 {
    font-size: 22px;
  }

  .fs_sm_23 {
    font-size: 23px;
  }

  .fs_sm_24 {
    font-size: 24px;
  }

  .fs_sm_25 {
    font-size: 25px;
  }

  .fs_sm_26 {
    font-size: 26px;
  }

  .fs_sm_xs_27 {
    font-size: 27px;
  }

  .fs_sm_28 {
    font-size: 28px;
  }

  .fs_sm_29 {
    font-size: 29px;
  }

  .fs_sm_30 {
    font-size: 30px;
  }

  .fs_sm_31 {
    font-size: 31px;
  }

  .fs_sm_32 {
    font-size: 32px;
  }

  .fs_sm_33 {
    font-size: 33px;
  }

  .fs_sm_34 {
    font-size: 34px;
  }

  .fs_sm_35 {
    font-size: 35px;
  }

  .fs_sm_38 {
    font-size: 38px;
  }

  .fs_sm_40 {
    font-size: 40px;
  }
  .fs_sm_50 {
    font-size: 50px;
  }
}
@media only screen and (max-width: 576px) {
  .fs_xs_10 {
    font-size: 10px;
  }

  .fs_xs_11 {
    font-size: 11px;
  }

  .fs_xs_12 {
    font-size: 12px;
  }

  .fs_xs_13 {
    font-size: 13px;
  }

  .fs_xs_14 {
    font-size: 14px;
  }

  .fs_xs_15 {
    font-size: 15px;
  }

  .fs_xs_16 {
    font-size: 16px;
  }

  .fs_xs_17 {
    font-size: 17px;
  }

  .fs_xs_18 {
    font-size: 18px;
  }

  .fs_xs_19 {
    font-size: 19px;
  }

  .fs_xs_20 {
    font-size: 20px;
  }

  .fs_xs_21 {
    font-size: 21px;
  }

  .fs_xs_22 {
    font-size: 22px;
  }

  .fs_xs_23 {
    font-size: 23px;
  }

  .fs_xs_24 {
    font-size: 24px;
  }

  .fs_xs_25 {
    font-size: 25px;
  }

  .fs_xs_26 {
    font-size: 26px;
  }

  .fs_xs_xs_27 {
    font-size: 27px;
  }

  .fs_xs_28 {
    font-size: 28px;
  }

  .fs_xs_29 {
    font-size: 29px;
  }

  .fs_xs_30 {
    font-size: 30px;
  }

  .fs_xs_31 {
    font-size: 31px;
  }

  .fs_xs_32 {
    font-size: 32px;
  }

  .fs_xs_33 {
    font-size: 33px;
  }

  .fs_xs_34 {
    font-size: 34px;
  }

  .fs_xs_35 {
    font-size: 35px;
  }

  .fs_xs_38 {
    font-size: 38px;
  }

  .fs_xs_40 {
    font-size: 40px;
  }
  .fs_xs_50 {
    font-size: 50px;
  }
}
.arrowuo_icon{
  position: fixed;
  bottom: 5rem;
  right: 3rem;
  z-index: 999;
}
.arrowuo_icon{
  animation: gototop 1.2s linear infinite alternate-reverse;
}
@keyframes gototop {
  0% {
    transform: translateY(-0.2rem);
  }
  100% {
    transform: translateY(1rem);
  }
}