.session_1 {
  background-color: white;
  width: 25%;
  padding: 6px;
  border-radius: 16px;
  color: black;
  position: absolute;
  top: 10%;
  left: 5%;
}

.session_2 {
  background-color: white;
  width: 20%;
  padding: 6px;
  border-radius: 16px;
  color: black;
  position: absolute;
  top: 10%;
  left: 34%;
}

.session_3 {
  background-color: white;
  width: 26%;
  padding: 6px;
  border-radius: 16px;
  color: black;
  position: absolute;
  top: 10%;
  left: 58%;
}

.session_4 {
  background-color: white;
  width: 25%;
  padding: 6px;
  border-radius: 16px;
  color: black;
  position: absolute;
  top: 35%;
  left: 17%;
}

.session_5 {
  background-color: #ff2852;
  width: 25%;
  padding: 6px;
  border-radius: 16px;
  color: white;
  position: absolute;
  top: 35%;
  left: 44%;
}

.session_6 {
  background-color: #2488b0;
  width: 25%;
  padding: 6px;
  border-radius: 16px;
  color: white;
  position: absolute;
  top: 35%;
  left: 72%;
}

.session_7 {
  background-color: #ff2852;
  width: 28%;
  padding: 6px;
  border-radius: 16px;
  color: white;
  position: absolute;
  top: 60%;
  left: 29%;
}

.session_8 {
  background-color: #ff2852;
  width: 25%;
  padding: 6px;
  border-radius: 16px;
  color: white;
  position: absolute;
  top: 60%;
  left: 58%;
}

.week-content-container {
  max-width: 100%; /* Set your desired max-width */
  overflow-x: auto; /* Make the container scrollable horizontally */
}

.my-div {
  width: auto;
}

/* Media query for small screens (max-width: 767px) */
@media only screen and (max-width: 767px) {
  .my-div {
    width: 100%;
  }
}

/* Media query for larger screens (min-width: 768px) */
@media only screen and (min-width: 1200px) {
  .my-div {
    width: 75%;
  }
}
